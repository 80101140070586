body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.customizedTable .ant-table-title {
  padding: 0 !important;
}

.ant-table-content {
  overflow-x: auto;
}

.ant-popover-content .ant-popover-inner {
  padding: 0 !important;
}

.loginForm .ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-bottom: 1px solid #d1d5db;
}

.loginForm .ant-divider-inner-text {
  color: #d1d5db;
}

.ant-picker-panel-container .ant-btn {
  background-color: #1677ff;
  color: white;
}
.preview .ant-modal-content .ant-modal-close {
  position: absolute;
  top: 1px !important;
  inset-inline-end: 5px !important;
}
.modal {
  width: 500px;
}
.modalStyle {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileImg .ant-image-mask {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.clientContact .form-control {
  width: 100% !important;
}

.form-control {
  width: 100% !important;
}

.custom-clip-path {
  clip-path: polygon(100% 100%, 100% 0%, 50% 0%);
}

.contentStyle {
  width: 700px;
  height: 40%;
  min-height: 500px;
}

.clients-container {
  display: flex;
  /* justify-content: flex-start; */
  /* justify-content: space-between; */
  flex-wrap: wrap;
  /* grid-template-columns: repeat(4, 33%); */
}

@media (min-width: 950px) and (max-width: 1350px) {
  .clients-container {
    grid-template-columns: repeat(2, 50%);
  }
}

@media (max-width: 950px) {
  .clients-container {
    grid-template-columns: repeat(1, 100%);
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgrey;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #e4e4e7;
}

*::-webkit-scrollbar-thumb {
  background-color: #d4d4d8;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1aa;
}

.ant-picker {
  width: 100%;
}

.role.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  padding: 0px !important;
}

.ant-dropdown.css-dev-only-do-not-override-2i2tap.ant-dropdown-placement-bottomRight{
min-width: 120px !important;
}

.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  max-height: 420px;
  overflow: auto;
}