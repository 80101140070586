.permission-denied {
  background: #233142;
  height: 100vh;
}

.permission-denied .whistle {
  width: 20%;
  margin: 0 40%;
  text-align: left;
  transform: translate(-50%, -50%);
  transform: rotate(0);
  transform-origin: 80% 30%;
  animation: wiggle 0.2s infinite;
  padding-top: 30px;
}

@keyframes wiggle {
  0% {
    transform: rotate(3deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3deg);
  }
}
.permission-denied h1 {
  margin-bottom: 20px;
  color: #facf5a;
  text-align: center;
  font-family: cursive;
  font-size: 90px;
  font-weight: 800;
}
.permission-denied h2 {
  color: #455d7a;
  text-align: center;
  font-family: cursive;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 20%;
}
