.fc-media-screen {
  background-color: white;
  width: 100%;
  max-height: 750px;
}

.fc-header-toolbar {
  padding: 1%;
  background-color: #001529;
  color: white;
}

.fc-dayGridMonth-view {
  padding: 0 12% 2% 12%;
}

.fc-button {
  color: yellow;
}

.fc-daygrid-day-top {
  display: block !important;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  background: #ffb612;
  border-color: #ffb612;
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: #c48f14;
  border-color: #c48f14;
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
  background: #ffb612;
  border-color: #ffb612;
}

.fc-toolbar-title {
  font-size: xx-large;
  font-weight: bold;
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  border-color: transparent !important;
  background-color: transparent !important;
  margin: 0;
}

.fc-col-header-cell-cushion {
  font-size: 16px;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: absolute;
  width: 100%;
}

.fc-daygrid-day {
  overflow-y: auto;
}

.fc-daygrid-day::-webkit-scrollbar {
  width: 2px;
}

.fc-daygrid-day ::-webkit-scrollbar-thumb {
  background-color: #c48f14;
}

.fc-daygrid-day::-webkit-scrollbar-thumb {
  background-color: #c48f14;
}

.fc-daygrid-event-harness {
  height: 22px;
}

.fc-event .fc-event-start .fc-event-end .fc-event-future .fc-daygrid-event .fc-daygrid-dot-event {
  height: 20px;
}

.fc-event.fc-daygrid-event,
.fc-event.fc-daygrid-event:hover {
  text-decoration: none;
  color: black;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 0;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin-left: 0;
}

.fc-event ::-webkit-scrollbar {
  height: 2px;
}

.fc-day :has(.reject) {
  background-color: #FFF1F0;
}

.fc-day :has(.accept) {
  background-color: #F6FFED;
}

.fc-day :has(.pending) {
  background-color: #E6F4FF;
}

.custom-event.pending {
  color: #0958DB;
}

.custom-event.accept {
  color: #389E0D;
}

.custom-event.reject {
  color: #CF1322;
}

@media only screen and (max-width: 768px) {
  .fc-media-screen {
    max-height: 700px;
  }
}

@media only screen and (max-width: 1024px) {
  .fc-media-screen {
    max-height: 500px;
  }

  .text-sm {
    font-size: 10px;
  }

  .fc-daygrid-day-number {
    font-size: 10px;
  }
}